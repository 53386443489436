<template>
  <div class="w-100 mb-3">
    <b-button
      v-b-toggle="`collapse-${id}`"
      class="collapse-card d-flex justify-content-between bg-white text-dark bg-white p-4 w-100"
      @click="visible = !visible"
    >
      <div class="d-flex flex-column text-left w-100">
        <div>
          <h5 class="font-weight-bold mb-1">{{ role.title }}</h5>
          <h5 class="role-description font-weight-normal">{{ role.description }}</h5>
        </div>
      </div>
      <hs-icon variant="light" :icon="visible ? 'chevron-up' : 'chevron-down'" />
    </b-button>
    <b-collapse :id="`collapse-${id}`" v-model="visible">
      <b-card>
        <div>
          <h6 class="font-weight-bold mb-4">
            {{ $t('my-account.views.my-team.roles-and-permissions.components.collapse-card.access-title') }}
          </h6>
          <div class="grid-access" :class="`grid-access-${id}`">
            <div
              class="d-flex align-items-start"
              :class="`grid-item-${index}`"
              v-for="(access, index) in role.access"
              :key="index"
            >
              <hs-icon icon="check" size="14" class="text-success mt-1" />
              <p class="font-size-sm ml-2" v-html="access"></p>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h6 class="font-weight-bold mb-4">
            {{ $t('my-account.views.my-team.roles-and-permissions.components.collapse-card.restrictions-title') }}
          </h6>
          <div class="grid-restrictions" :class="`grid-restriction-${id}`">
            <div
              class="d-flex align-items-start"
              :class="`grid-item-${index}`"
              v-for="(restriction, index) in role.restrictions"
              :key="index"
            >
              <hs-icon icon="times" variant="light" size="14" class="text-danger mt-1" />
              <p class="font-size-sm ml-2" v-html="restriction"></p>
            </div>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    role: {
      required: true,
      type: Object,
    },
    id: {
      type: Number,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.collapse-card {
  border-radius: 4px;
  &.not-collapsed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .role-description {
    color: $grey-32;
  }
}

/deep/ .collapse {
  .card {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .role-description {
      padding-top: 10px;
    }
  }
}

.grid-access,
.grid-restrictions {
  row-gap: 16px;
  column-gap: 5vw;
}

.grid-access-0 {
  display: grid;
  grid-template: repeat(3, auto) / repeat(3, 13vw);
  .grid-item-0 {
    grid-row: 1 / 3;
  }
  .grid-item-3 {
    grid-row: 3 / 4;
  }
  .grid-item-6 {
    grid-row: 4 / 5;
  }
  .grid-item-7 {
    grid-row: 3 / 5;
  }
}

.grid-restriction-0,
.grid-restriction-1,
.grid-restriction-2,
.grid-restriction-3,
.grid-restriction-4,
.grid-restriction-5,
.grid-access-1,
.grid-access-2,
.grid-access-3,
.grid-access-5 {
  display: grid;
  grid-auto-flow: column;
}

.grid-access-3 {
  grid-template: repeat(3, auto) / repeat(4, 13vw);
  .grid-item-1,
  .grid-item-6 {
    grid-row: 2 / 4;
  }
}

.grid-restriction-0 {
  grid-template: repeat(3, auto) / repeat(4, 13vw);
}

.grid-access-1 {
  .grid-item-1 {
    grid-row: 2 / 4;
  }
  .grid-item-4,
  .grid-item-8 {
    grid-row: 2 / 3;
  }

  .grid-item-5,
  .grid-item-9 {
    grid-row: 3 / 4;
  }

  .grid-item-12 {
    grid-row: 2 / 5;
  }
}

.grid-access-1,
.grid-restriction-3,
.grid-restriction-2 {
  grid-template: repeat(4, auto) / repeat(4, 13vw);
}

.grid-restriction-3 {
  .grid-item-11 {
    grid-row: 1 / 3;
  }
}

.grid-restriction-1 {
  grid-template: repeat(2, 1fr) / repeat(4, 13vw);
}

.grid-access-2 {
  grid-template: repeat(2, auto) / repeat(3, 13vw);
  .grid-item-4 {
    grid-row: 1 / 3;
  }
}

.grid-access-4 {
  display: grid;
  row-gap: 16px;
  grid-template: repeat(5, 1fr) / repeat(4, 13vw);
  grid-auto-flow: row;
  .grid-item-4,
  .grid-item-5,
  .grid-item-6 {
    grid-row: 2 / 3;
  }
  .grid-item-7 {
    grid-row: 2 / 4;
  }
}

.grid-restriction-4 {
  grid-template: repeat(2, auto) / repeat(3, 13vw);
}

.grid-access-5 {
  grid-template: repeat(3, 1fr) / repeat(2, 13vw);
  .grid-item-1 {
    grid-row: 2 / 4;
    grid-column: 1 / 3;
  }
  .grid-item-2,
  .grid-item-3 {
    grid-column: 3 / 4;
  }
}

.grid-restriction-5 {
  grid-template: repeat(5, 1fr) / repeat(4, 13vw);
  .grid-item-13 {
    grid-row: 4 / 6;
  }
  .grid-item-16 {
    grid-row: 3 / 5;
  }
}

@media (max-width: $screen-md) {
  /deep/ .collapse {
    max-height: 312px;
    overflow-y: scroll;
    .grid-access,
    .grid-restrictions {
      display: flex;
      flex-direction: column;
    }

    .grid-access-5 {
      .grid-item-1 {
        width: 100%;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .grid-access,
  .grid-restrictions {
    column-gap: 8vw;
  }
}
</style>
