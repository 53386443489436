<template>
  <PageLayout>
    <template #header>
      <PageHeader
        :title="$t('my-account.views.my-team.roles-and-permissions.title')"
        :subTitle="$t('my-account.views.my-team.roles-and-permissions.subtitle')"
        backRoute="MyTeam"
        :backText="$t('my-account.views.my-team.roles-and-permissions.back-text')"
        :backOnlyMobile="false"
      >
      </PageHeader>
    </template>

    <section class="p-3 p-md-5">
      <div
        class="rounded-sm d-flex flex-column flex-md-row align-items-start align-items-md-center bg-white 
        text-dark bg-white p-4 mb-3 d-flex justify-content-between"
      >
        <h5 class="font-weight-bold mb-2 mb-md-0">
          {{ $t('my-account.views.my-team.roles-and-permissions.roles.admin.title') }}
        </h5>
        <div class="admin-informative d-flex align-items-center p-2 rounded-sm">
          <hs-icon icon="exclamation-circle" :size="17" />
          <span class="ml-2">{{ $t('my-account.views.my-team.roles-and-permissions.roles.admin.description') }}</span>
        </div>
      </div>
      <CollapseCard v-for="(role, index) in rolesPermissions" :role="role" :id="index" :key="index" />
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import CollapseCard from './components/CollapseCard.vue';
import RolesPermissions from '@/data/roles_permissions.js';

export default {
  name: 'RolesAndPermissions',
  components: {
    CollapseCard,
    PageLayout,
    PageHeader,
  },
  data() {
    return {
      rolesPermissions: RolesPermissions(key => this.$t(key)),
    };
  },
};
</script>

<style lang="scss" scoped>
h5 {
  font-size: 1rem !important;
}
.admin-informative {
  background-color: #b3dafe;
}
</style>
