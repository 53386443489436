export default translate => [
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.sales-assistance.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.sales-assistance.description'),
    access: [
      translate(
        'my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-assistance-products-catalog'
      ),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members-area'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-assistance-sales'),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.business-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
    ],
  },
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.commercial-and-sales.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.commercial-and-sales.description'),
    access: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate(
        'my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.commercial-sales-products-catalog'
      ),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
      translate(
        'my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.commercial-and-sales-members-area'
      ),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.product-creation'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.business-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
    ],
  },
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.content.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.content.description'),
    access: [
      translate(
        'my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-products-catalog'
      ),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-members-area'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.product-creation'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.business-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
    ],
  },
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.financial.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.financial.description'),
    access: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate(
        'my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-assistance-products-catalog'
      ),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.account-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.product-creation'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.financial-members-area'),
    ],
  },
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.management.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.management.description'),
    access: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.product-creation'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.management-members-area'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.products-catalog'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.business-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
    ],
  },
  {
    title: translate('my-account.views.my-team.roles-and-permissions.roles.support.title'),
    description: translate('my-account.views.my-team.roles-and-permissions.roles.support.description'),
    access: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.products-catalog'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.support-content-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.members'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.messages'),
    ],
    restrictions: [
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.homepage'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.product-creation'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales-config'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.funnels'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.leads'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.campaign'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.sales'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.wallet'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.affiliates'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.auto-pilot'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.upgrade'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.business-data'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.domains'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.plans'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.your-team'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.vitrine'),
      translate('my-account.views.my-team.roles-and-permissions.roles.access-and-restrictions.support-members-area'),
    ],
  },
];
